<template>
  <div
    class="produit-actions"
    :class="{ activeFavoris: favorisActive }"
  >
    <div class="d-flex">
      <button @click="toggleFavourite">
        <img v-show="!favorisActive" src="@/assets/produit-favoris-empty.svg" />
        <img v-show="favorisActive" src="@/assets/produit-favoris-full.svg" />
      </button>
      <router-link :to="{ name: 'product', params: { productId, shopSlug: shop.id } }">
        <img
          src="@/assets/produit-details-black.svg"
          class="produit-details"
        />
      </router-link>
    </div>
    <ProduitQuantityEditor
      v-if="offer"
      :partner-id="partner.id"
      :shop-id="shopId"
      :offer="offer"
      :article="product"
      class="cart d-flex align-items-center"
    />
  </div>
</template>

<script>
import ProduitQuantityEditor from '@/components/Produits/ProduitQuantityEditor'

export default {
  name: 'ProduitActionButtons',
  components: { ProduitQuantityEditor },
  props: {
    shopId: { type: String },
    offer: { type: Object },
    product: { type: Object },
    productId: { type: String },
    favourite: { type: Boolean }
  },

  data: () => ({
    loading: false
  }),

  computed: {
    partner() {
      return this.$store.getters['partner/current']
    },

    shop() {
      return { id: this.shopId }
    },

    favorisActive: {
      get() {
        return this.favourite
      },
      set(v) {
        this.$emit('update:favourite', v)
      }
    }
  },

  methods: {
    toggleFavourite() {
      if (this.loading) {
        return
      }
      this.loading = true
      return this.$store.dispatch('user/productFavouriteToggle', { shopId: this.shop.id, productId: this.productId })
        .then(active => {
          this.favorisActive = active
        })
        .catch(err => {
          if (err?.response?.status === 401) {
            this.$store.commit('modal/show', {
              component: 'LoginPopup',
              params: {
                returnPath: `${this.$route.path}#shops`
              }
            })
            return
          }
          this.$err(err)
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.produit-actions {
  display: flex;
  justify-content: space-between;
  margin: auto;
}
.produit-actions img {
  margin: 0 10px;
  cursor: pointer;
  max-width: 25px;
}
.produit-actions button {
  border: none;
  background: transparent;
  padding: 0;
}
.produit-actions button img {
  margin: 0;
}
.produit-actions:not(.activeFavoris) {
  button.produit-favoris-full {
    display: none;
  }
  button.produit-favoris-empty {
    display: flex;
  }
}
.produit-actions.activeFavoris {
  button.produit-favoris-full {
    display: flex;
  }
  button.produit-favoris-empty {
    display: none;
  }
}
.cart a {
  color: #E45E43;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
.cart:not(.activeCart) {
  button.cart-red, a {
    display: none;
  }
  button.cart-black {
    display: flex;
  }
}
.cart.activeCart {
  button.cart-red, a {
    display: flex;
  }
  button.cart-black{
    display: none;
  }
}
</style>
