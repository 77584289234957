<template>
  <div
    class="produit-item"
  >
    <router-link :to="{ name: 'product', params: { productId, shopSlug: shop.slug || shop.id } }">
      <img
        class="photo"
        :src="image ? $resize(image, { w: 400, h: 400, f: 'contain' }) : '@/assets/photo-produit.png'"
      >
    </router-link>
    <div v-if="!hideTitle">
      <span class="shop-name">{{ shop.name }}</span>
    </div>
    <div
      class="product-name"
    >
      <span v-if="product.brand">{{ product.brand }} </span>{{ product.getTitle($l) }}
      <em>{{ product | packaging($t) }}</em>
    </div>
    <div
      v-if="offer"
      class="price"
      :class="{ active: isPricePromotion }"
    >
      <span class="normal-price">{{ offer.price | price({ article: product }) }}</span>
      <span v-if="promotion" class="promo-price">{{ promotion.price | price({ article: product }) }}</span>
    </div>
    <ProduitActionButtons
      :shop-id="shopId"
      :product-id="product.id"
      :product="product"
      :offer="offer"
      :favourite.sync="favorisActive"
    />
  </div>
</template>

<script>
import ProduitActionButtons from '@/components/Produits/ProduitActionButtons'
export default {
  name: 'SelectionSliderItem',
  components: { ProduitActionButtons },
  props: {
    hideTitle: { type: Boolean },
    shopObject: { type: Object },
    shopId: { type: String },
    shopSlug: { type: String },
    productId: { type: String },
    image: { type: String },
    packaging: { type: Object },
    favourite: { type: Boolean },
    product: { type: Object },
    offer: { type: Object }
  },
  data() {
    return {
      loading: false,
      popupAdd: false
    }
  },
  computed: {
    partner() {
      return this.$store.getters['partner/current']
    },
    shop() {
      return this.shopObject || this.$store.getters['shop/current'] || { id: this.shopId, slug: this.shopSlug }
    },
    favorisActive: {
      get() {
        return this.favourite
      },
      set(v) {
        this.$emit('update:favourite', v)
      }
    },
    promotion() {
      return this.offer?.promotion
    },
    isPricePromotion() {
      return this.promotion && this.promotion.price !== this.offer.getBaseRawPrice()
    }
  },
  methods: {
    toggleFavourite() {
      if (this.loading) {
        return
      }
      this.loading = true
      return this.$store.dispatch('user/productFavouriteToggle', { shopId: this.shop.id, productId: this.productId })
        .then(active => {
          this.favorisActive = active
        })
        .catch(err => {
          if (err?.response?.status === 401) {
            this.$store.commit('modal/show', {
              component: 'LoginPopup',
              params: {
                returnPath: `${this.$route.path}#shops`
              }
            })
            return
          }
          this.$err(err)
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.produit-item {
  padding: 20px;
  background-color: white;
  box-shadow: 1px 1px 6px #00000085;
  position: relative;
}
img {
  max-width: 100%;
}
.shop-name {
  position: absolute;
  top: 10px;
  left: 0;
  background-color: var(--yellow-color);
  font-weight: 700;
  color: white;
  padding: 10px;
}
.product-name {
  padding-top: 15px;
  padding-bottom: 10px;
  text-align: left;
  font-weight: 800;
  min-height: 100px;

  em {
    display: block;
    font-style: normal;
    font-weight: normal;
  }

  p {
    font-weight: normal;
  }
}
.price {
  text-align: right;
}
.produit-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding-top: 10px;
}
.produit-actions img {
  margin: 0 10px;
  cursor: pointer;
  max-width: 25px;
}
.price:not(.active) {
  .promo-price {
    display: none !important;
  }
}
.price.active {
  .normal-price {
    text-decoration: line-through;
    color: var(--red-color);
  }
  .promo-price {
    padding-left: 10px;
  }
}
.produit-actions button {
  border: none;
  background: transparent;
  padding: 0;
}
.produit-actions button img {
  margin: 0;
}
.produit-actions:not(.activeFavoris) {
  button.produit-favoris-full {
    display: none;
  }
  button.produit-favoris-empty {
    display: flex;
  }
}
.produit-actions.activeFavoris {
  button.produit-favoris-full {
    display: flex;
  }
  button.produit-favoris-empty {
    display: none;
  }
}
.cart a {
  color: var(--red-color);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
.cart:not(.activeCart) {
  button.cart-red, a {
    display: none;
  }
  button.cart-black {
    display: flex;
  }
}
.cart.activeCart {
  button.cart-red, a {
    display: flex;
  }
  button.cart-black{
    display: none;
  }
}
</style>
